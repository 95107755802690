@media (max-width: 960px) {
  .live-clock {
    font-size: 1rem;
  }
  .snackbar-notification {
    width: 100%;
    font-size: 1rem;
  }
  .month-selector-container {
    flex-direction: column !important;
    gap: 15px;
    margin-bottom: 50px !important;
  }
  .refresh-data-button {
    display: none !important;
  }
  .live-clock-container {
    display: flex !important;
    justify-content: flex-end !important;
    margin: 10px 0 15px 0;
  }
  .info-text {
    font-size: 0.8rem !important;
    position: absolute !important;
    left: 50%;
    transform: translate(-50%, -30px);
    width: 100vw;
    text-align: center;
  }
  .chart-ms div svg .MuiChartsAxis-directionX g text {
    transform: rotate(-270deg);
  }
  .chart-ms div svg .MuiChartsAxis-directionX g text {
    transform-origin: -9px 23px;
    font-size: 12px;
  }
}
@media (min-width: 1920px) {
  *::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: rgba(10, 10, 10, 0.9);
    cursor: pointer;
  }
  *::-webkit-scrollbar {
    width: 5px;
    height: 12px;
    background-color: rgba(0, 0, 0, 0.9);
    cursor: pointer;
  }
  *::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgba(255, 255, 255, 0.9);
    cursor: pointer;
  }
}
@media screen and (max-width: 767px) {
  *::-webkit-scrollbar-track,
*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
    display: none;
  }
}/*# sourceMappingURL=breakpoints.css.map */