@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  background: url("../images/bg.jpg") center center fixed;
  min-height: 100vh;
  max-height: auto;
}
* {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
  font-family: "Montserrat", sans-serif !important;
  scroll-behavior: smooth;
}
.grey-background {
  background-color: #b9b5b5 !important;
}
.red-background {
  background-color: rgb(204, 20, 20) !important;
}
.blue-in-progress-background {
  background-color: rgb(20, 152, 204) !important;
}
.blue-accepted-background {
  background-color: rgb(20, 26, 204) !important;
}
.purple-rejected-background {
  background-color: rgb(100, 20, 204) !important;
}
.green-background {
  background-color: rgb(67, 176, 36) !important;
}
.yellow-background {
  background-color: rgb(205, 223, 8) !important;
}

.orange-background {
  background-color: rgb(238, 159, 14) !important;
}
.table-toolbar .MuiTablePagination-displayedRows,
.table-toolbar .MuiTablePagination-select,
.table-toolbar .MuiTablePagination-selectLabel {
  color: white;
}
.cell .MuiDataGrid-cellContent {
  display: none !important;
}
.table-toolbar-user .MuiTablePagination-toolbar {
  display: none !important;
}
*::-webkit-scrollbar-track {
  background-color: rgba(10, 10, 10, 0.9);
  cursor: pointer;
  display: none;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 12px;
  cursor: pointer;
}

*::-webkit-scrollbar-thumb {
  box-shadow:inset 0 0 6px rgba(0, 0, 0, 0.9);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.9);
  background-color: rgba(255, 255, 255, 0.9);
  cursor: pointer;
}
html::-webkit-scrollbar-track,
html::-webkit-scrollbar,
html::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-track body::-webkit-scrollbar body::-webkit-scrollbar-thumb,
.root div *::-webkit-scrollbar-track .root div *::-webkit-scrollbar .root div *::-webkit-scrollbar-thumb {
  display: none !important;
}

.table-container {
  margin: 0 auto;
  max-width: 95%;
}
.manage-users-container{
  
  margin-right: auto;
  margin-left: auto;
  max-width: 80% !important;
}
.MuiDataGrid-root .MuiSvgIcon-root {
  color: white;
}

.custom-text-area {
  resize: both;
  margin-top: 15px;
  width: 100%;
  max-width: 1000px;
  min-width: 250px;
  max-height: 1000px;
  min-height: 250px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 12px;
  border-radius: 12px 12px 0 12px;
  color: #24292f;
  background: #fff;
  border: 1px solid #d0d7de;
  box-shadow: 0px 2px 2px #f6f8fa;
}
.custom-text-area:hover {
  border-color: #8c959f;
}

.custom-text-area:focus {
  border-color: #8c959f;
}

.custom-text-area:focus-visible {
  outline: 0;
}

.report-text {
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  font-weight: 500;
  padding-left: 24px;
  padding-top: 16px;
  padding-bottom: 8px;
  white-space: pre-line;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 1000ms ease-out;
}
.legend-table .MuiDataGrid-cell {
  padding-left: 0px !important;
}
.legend-table .MuiDataGrid-columnHeaderTitle,
.legend-table .MuiDataGrid-columnHeaderTitleContainerContent {
  width: 100%;
  text-align: center;
}
.legend-table .MuiDataGrid-footerContainer {
  display: none;
}

.MuiDataGrid-iconSeparator {
  color: transparent !important;
}
.snackbar-notification {
  width: 500px;
}

.table-toolbar-user .MuiDataGrid-footerContainer {
  height: 10px !important;
  min-height: 15px;
}

.MuiPickersLayout-actionBar {
  display: none !important;
}

.MuiTimeClock-arrowSwitcher {
  right: 0px !important;
}

.is-clocked_in  div {
 
    background: white;
    padding: 5px;
    border-radius: 25px;
    height: 8px;
    width: 8px;
    margin-top: 10px;
  
}

.is-clocked_in {
  display: flex;
  align-items: flex-start !important ;
}

.green-clocked-in-background {
  background-color: rgb(67, 176, 36) !important;
}
.green-clocked-in-background   div {
 
  background: white;
  padding: 5px;
  border-radius: 25px;
  height: 8px;
  width: 8px;
  margin-top: 10px;
  margin-left: 5px;

}